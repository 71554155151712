// i18next-extract-mark-ns-start products-valdyn

import { LINKS, VALDYN_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import ContactForm from '@components/ContactForm';
import List from '@components/List';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import ResourceListing from '@components/views/ResourceListing';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductValdynPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const icon = getImage(data.icon.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					
                    {icon && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={icon}
								alt="VALDYN"
								className="icon-image"
							/>
						</div>
					)}

					<Trans i18nKey="content_2">
					    <p>
							VALDYN is a multi-body dynamic and kinematic simulation
							package specifically developed for valvetrain and
							drive-system analysis and cam and spring pack design.
							With its detailed ‘building block’ models of engine
							components, it is significantly quicker to build models
							with VALDYN than with general-purpose dynamics tools and
							quicker to run because model refinement focuses on the
							critical aspects related to engines.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{VALDYN_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>

			<PageSection title={t("_KeyFeatures")} layout="wide">
				<Trans i18nKey="features">
					<List type="grid">
						<li>
							‘Building block’ structure enables modelling of
							virtually any valvetrain, from individual valve lines to
							complete systems incorporating crankshaft and camshafts
						</li>
						<li>
							Modelling of drive systems, including gears, belts and
							chains
						</li>
						<li>
							Dynamic bodies to include finite element (FE) models of
							guides, rockers, shafts and casings
						</li>
						<li>
							Embedded FE solver to run the FE dynamic reduction and
							back-substitution to evaluate FE stresses or vibrations
						</li>
						<li>
							Data input using expressions involving case-varying
							parameters
						</li>
						<li>
							Frequency domain solver including forced-damped solution
							of linear models
						</li>
						<li>Dynamic and kinematic analysis of valve trains</li>
						<li>
							Modelling of variable valve actuation (VVA) and variable
							valve timing (VVT) mechanisms
						</li>
						<li>Animated output in the time and frequency domains</li>
						<li>Results plotted automatically</li>
						<li>3D frequency-response plots</li>
						<li>
							Plotting of mathematical expressions involving several
							outputs
						</li>
						<li>
							Run distribution using network computing resources to
							speed up multi-case simulations
						</li>
						<li>
							Links to ENGDYN and FEARCE for engine structural
							analysis
						</li>
						<li>
							Co-simulation with other Realis Simulation formerly
							Ricardo Software and third-party programmes, such as
							Simulink
						</li>
					</List>
				</Trans>
			</PageSection>

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>

		</Page>
	);
};

export default ProductValdynPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-valdyn", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/valdyn-hero.jpg" }) {
		...imageBreaker
	}
	icon: file(relativePath: { eq: "content/rs_valdyn.png" }) {
		...imageBreaker
	}
	resources: allMarkdownRemark(
		filter: {
			fileAbsolutePath: { regex: "/resources/" }
			fields: { language: { eq: $language } }
			frontmatter: { products: { in: "VALDYN" } }
		}
		sort: { fields: frontmatter___date, order: DESC }
		limit: 6
	) {
		nodes {
			fields {
				slug
			}
			frontmatter {
				title
				summary
				image {
					...imageStandard
				}
				type
			}
		}
	}
}
`;
