import { LINKS, countryList } from '@utils/constants';
import { slugify } from '@utils/helpers';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useState } from 'react';

import Checkbox from './forms/Checkbox';
import Input from './forms/Input';
import Select from './forms/Select';
import TextArea from './forms/TextArea';

interface FormProps {
	name?: string;
	company?: string;
	country?: string;
	email?: string;
	phone?: string;
	message?: string;
	marketing?: boolean;
}

const Form = ({ name, title }: { name: string; title?: string }) => {
	const [formSent, setFormSent] = useState(false);
	const [formData, setFormData] = useState<FormProps>();

	const encode = (data: any) => {
		return Object.keys(data)
			.map(
				(key) =>
					encodeURIComponent(key) +
					'=' +
					encodeURIComponent(data[key])
			)
			.join('&');
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({
				'form-name': name,
				...formData,
			}),
		})
			.then((res) => {
				setFormSent(true);
			})
			.catch((error) => alert(error));
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const name = e.target.name;
		let value;

		switch (e.target.type) {
			case 'checkbox':
				value = e.target.checked ? e.target.checked : false;
				break;

			default:
				value = e.target.value;
				break;
		}

		setFormData({ ...formData, [name]: value });
	};

	const { t } = useTranslation();

	return formSent ? (
		<p className="alert--success">
			{t("_FormSuccess") /* i18next-extract-disable-line */}
		</p>
	) : (
		<form
			name={name}
			data-netlify="true"
			netlify-honeypot="bot-field"
			method="POST"
			className="form__container has-loaded"
			onSubmit={(e) => handleSubmit(e)}>
			<>
				{title && <h2>{title}</h2>}

				<p>
					<Trans i18nKey="_FormAcademia">
						{t("_FormAcademiaNote1") /* i18next-extract-disable-line */}{' '}
						<Link to={LINKS.SUPPORT_ACADEMIA} target="_blank">
							{t("_Academia") /* i18next-extract-disable-line */}{' '}
						</Link>		
						{t("_FormAcademiaNote2") /* i18next-extract-disable-line */}{' '}
						<b>{t("_LicenseRequest") /* i18next-extract-disable-line */}</b>{' '}			
						{t("_FormAcademiaNote3") /* i18next-extract-disable-line */}
					</Trans>
				</p>
				
				<label hidden>
					Don&apos;t fill this out if you&apos;re human:{' '}
					<input name="bot-field" />
				</label>
				<input type="hidden" name="form-name" value={name} />
				<input type="hidden" name="subject" data-remove-prefix value="Form submission from contact form" />

				<Input
					label={t("_FormName") /* i18next-extract-disable-line */}
					name="name"
					handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleChange(e)
					}
					className={formData?.name ? 'form-input--filled' : ''}
					required
				/>
				<Input
					label={t("_FormCompany") /* i18next-extract-disable-line */}
					name="company"
					handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleChange(e)
					}
					className={formData?.company ? 'form-input--filled' : ''}
					required
				/>
				<Select
					label={t("_FormCountry") /* i18next-extract-disable-line */}
					name="country"
					handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleChange(e)
					}
					defaultValue="">
					<option hidden disabled value="">
						-- {t("_FormSelectCountry") /* i18next-extract-disable-line */} --
					</option>
					{countryList.map((country, i) => (
						<option key={i} value={slugify(country)}>
							{t(`_Country${country}`) /* i18next-extract-disable-line */}
						</option>
					))}
					required
				</Select>

				<p>
					<Trans i18nKey="_FormAcademia">
					<small><b>{t("_FormEmailNote") /* i18next-extract-disable-line */}</b></small>
					</Trans>
				</p>
				
				<Input
					label={t("_FormEmail") /* i18next-extract-disable-line */}
					name="email"
					type="email"
					className={
						formData?.email !== undefined
							? 'form-input--filled'
							: ''
					}
					handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleChange(e)
					}
					required
				/>
				
				<Input
					label={t("_FormPhone") /* i18next-extract-disable-line */}
					name="phone"
					//Allow spaces and input of country code with +()
					pattern="[\s+()0-9]{1,20}"
					className={
						formData?.phone !== undefined
							? 'form-input--filled'
							: ''
					}
					handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleChange(e)
					}
				/>
				<TextArea
					label={t("_FormMessage") /* i18next-extract-disable-line */}
					name="message"
					className={
						formData?.message !== undefined
							? 'form-input--filled'
							: ''
					}
					handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleChange(e)
					}
					required
				/>
				<p>
					<Trans i18nKey="_FormPrivacy">
						{t("_FormPrivacyNotice") /* i18next-extract-disable-line */}{' '}
						<Link to={LINKS.PRIVACY} target="_blank">
							{t("_FormPrivacyLink") /* i18next-extract-disable-line */}
						</Link>
						.
					</Trans>
				</p>

				<Checkbox
					label={t("_FormMarketing") /* i18next-extract-disable-line */}
					name="marketing"
					handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleChange(e)
					}
					className='form-row--checkbox--small'
				/>

				<button className="button" type="submit">
					{t("_FormSubmit") /* i18next-extract-disable-line */}
				</button>
			</>
		</form>
	);
};

export default Form;
